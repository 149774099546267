import React from 'react'
import '../components/BottomFooter.css' ;
import logo2 from'../components/HushStoryLogo2.png';
import logo1 from '../components/HushStoryLogo1.png';

export default function BottomFooter() {
  return (
    <div>
        
        <footer className="footer">
  <div className="footer-left col-md-4 col-sm-6">
    <p className="about">
      <span> About the company</span> HushStory is a platform that focuses on bringing up the stories of the people and making them more accessible to the public. It lets you watch and re-watch your favorite stories.
    </p>
    <p className="menu">
      <a href="/privacypolicy"> Privacy Policy </a> |
      <a href="/termsandconditions">  &nbsp; Terms and Conditions</a> 
    </p>
    {/* <div className="icons">
      <a href="/"><i className="fa fa-facebook"></i></a>
      <a href="/"><i className="fa fa-twitter"></i></a>
      <a href="/"><i className="fa fa-linkedin"></i></a>
      <a href="/"><i className="fa fa-google-plus"></i></a>
      <a href="/"><i className="fa fa-instagram"></i></a>
    </div> */}
  </div>
  <div className="footer-center col-md-4 col-sm-6" style={{padding: '30px'}}>
    <div>
      {/* <i className="fa fa-map-marker"></i>
      <p><span> Street name and number</span> City, Country</p> */}
    </div>
    <div>
      {/* <i className="fa fa-phone"></i> */}
      <p> You can Share your Story With us at : </p>
    </div>
    <div>
      {/* <i className="fa fa-envelope"></i> */}
      <p> asli.fun.co@gmail.com</p>
    </div>
  </div>
  <div className="footer-right col-md-4 col-sm-6">
    <h2> <img src={logo1} width="50" height ="50" alt="logo" /> <img src={logo2} width="150"  alt="logo" /></h2>
    <p className="menu">
      <a href="/"> HushStory</a> |
      <a href="/poem">&nbsp; Poem </a> |
      <a href="/ebooks">&nbsp; E-Books </a> | 
      <a href="https://tools.hushstory.com/">  &nbsp; Tools</a> |
      <a href="/about">  &nbsp; About</a> 
      
    </p>
    <p className="name"> HushStory &copy; 2016</p>
  </div>
</footer>
        
    </div>
  )
}
