import React, { useEffect, useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Divider } from "antd";
import { useParams } from "react-router-dom";
import "../components/SiteBody.css";
import RelatedStory from "./RelatedStory";
import { Helmet } from "react-helmet-async";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

// function urlify(text) {
//   var urlRegex = /(https?:\/\/[^\s]+)/g;
//   return text.replace(urlRegex, function(url) {
//     return '<a href="' + url + '">' + url + '</a>';
//   })
//   // or alternatively
//   // return text.replace(urlRegex, '<a href="$1">$1</a>')
// }

export default function SiteBody(props) {
  const { storyid } = useParams();

  const API = `https://api.hushstory.com/api/${props.title}?storyId=${storyid}`;

  const [data, setData] = useState([]);

  const fetchApiData = async (url) => {
    try {
      const res = await fetch(url); //fetch API as url
      const response = await res.json();
      setData([response]); // convert response in JSON
      // console.log([response]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchApiData(API);
  }, [API]);


  return (
    <>

    <div style={{minHeight: '900px'}}>
      {data.map((s) => (
        <div style={{ marginTop: "20px", marginBottom: "20px" }} key={s.mainStory.storyId}>
          <Helmet>
          <title> {s.mainStory.title} </title>
          <meta name="description" content={s.mainStory.description.slice(0,150)}/>
          <link rel="canonical" href={`/${props.title}/${s.mainStory.storyId}`}/>
          </Helmet>
          <div>
            <div className="container-fluid">
              <Row className="justify-content-md-center">
                <Col xs lg="3" style={{padding:'40px'}} >
                  <a
                    href={`/${props.title}/${s.otherStories[0].storyId}`}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Card >
                      <Card.Img variant="top" src={s.otherStories[0].images} alt={`Other-Story-Thumbnail-${s.otherStories[0].title}`}/>
                      <Card.Body>
                        <Card.Title>{s.otherStories[0].title}</Card.Title>
                      </Card.Body>
                    </Card>
                  </a>
                  {/* <div className='d-flex justify-content-center ' >
                            <img className='img-fluid' src={s.otherStories[0].images}/>
                            <div><h5>{s.otherStories[0].title}</h5></div>
                        </div> */}
                </Col>

                        {/* -------------------Main Story------------------ */}

                <Col md lg="6">
                  <div className="container d-flex justify-content-center text-center ">
                    {" "}
                    <h1 className="text-capitalize">
                      {" "}
                      {s.mainStory.title}
                    </h1>{" "}
                  </div>
                  <Divider> </Divider>
                  {/* <div className='embed-responsive embed-responsive-16by9 d-flex justify-content-center'  >
                        <iframe  className="embed-responsive-item " width="100%" height="500 "  src={s.mainStory.images} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div> */}
                  <div className="d-flex justify-content-center">  

                    
                   {s.mainStory.youtubeVideoId
                    ?<div className="iframeBox"> <iframe className="responsive-iframe" src={`https://www.youtube.com/embed/${s.mainStory.youtubeVideoId}`} title={`Main-Story-Box-${s.mainStory.title}`} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></div>
                    : s.mainStory.affLink ?    
                      <a href={s.mainStory.affLink} style={{width: "100%"}}><img    className="img-fluid"  src={s.mainStory.images}    alt={`Main-Story-Thumbnail-${s.mainStory.title}`}  /></a>
                    : <img    className="img-fluid"  src={s.mainStory.images}    alt={`Main-Story-Thumbnail-${s.mainStory.title}`}  />}
                  
                 
                  
                  </div>
                </Col>

                      {/* -------------------End of Main Story------------------ */}

                <Col xs lg="3" style={{padding:'40px'}}  >
                  <a
                    href={`/${props.title}/${s.otherStories[1].storyId}`}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Card >
                      <Card.Img variant="top" src={s.otherStories[1].images} alt={`Other-Story-Thumbnail-${s.otherStories[1].title}`}/>
                      <Card.Body>
                        <Card.Title>{s.otherStories[1].title}</Card.Title>
                      </Card.Body>
                    </Card>
                  </a>
                </Col>
              </Row>
              <Row className="justify-content-md-center" >
                <Col xs lg="3" style={{padding:'40px'}} >
                  <a
                    href={`/${props.title}/${s.otherStories[2].storyId}`}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Card >
                      <Card.Img variant="top" src={s.otherStories[2].images} alt={`Other-Story-Thumbnail-${s.otherStories[2].title}`}/>
                      <Card.Body>
                        <Card.Title>{s.otherStories[2].title}</Card.Title>
                      </Card.Body>
                    </Card>
                  </a>
                </Col>
                <Col md="6">
                  <Divider> </Divider>
                  <div className="container justify-content-center main-description text-break">
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                      {s.mainStory.description}
                    </ReactMarkdown>
                    <p className="text-end fw-bold fst-italic">- {s.mainStory.author ? s.mainStory.author : "hushstory.com" }</p>               
                    <p className="text-end text-muted">{s.mainStory.createdAt}</p>
                  </div>
                </Col>
                <Col xs lg="3" style={{padding:'40px'}} >
                  <a
                    href={`/${props.title}/${s.otherStories[3].storyId}`}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Card >
                      <Card.Img variant="top" src={s.otherStories[3].images} alt={`Other-Story-Thumbnail-${s.otherStories[3].title}`}/>
                      <Card.Body>
                        <Card.Title>{s.otherStories[3].title}</Card.Title>
                      </Card.Body>
                    </Card>
                  </a>
                </Col>
              </Row>
            </div>
          </div>
          <div className="container">
            <h2 className="container d-flex justify-content-center">
              Related Stories
            </h2>
            <div className="container">
            <Row xs={1} md={2} className="g-4" style={{padding:'30px'}}>
              {s.otherStories.slice(4).map((o) => {
                return (
                  <div className="col-md-4" key={o.storyId}>
                    <a href={`/${props.title}/${o.storyId}`} style={{ textDecoration: "none", color: "black" }}><RelatedStory title={o.title} images={o.images} date={o.createdAt}/></a>
                  </div>
                );
              })}
            </Row>
            </div>
          </div>
        </div>
      ))}
    </div>

    </>
  );
}
