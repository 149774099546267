import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarMenu from './components/NavbarMenu';
import './App.css';
import SiteBody from './components/SiteBody';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import BottomFooter from './components/BottomFooter';
import AboutUs from './components/AboutUs';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditions from './components/TermsAndConditions';



function App() {

  return (
   
    <Router>
      <div>
        <div>
          <NavbarMenu/>
        </div>  
        <Routes>
          <Route path='/' element={<SiteBody title="story" description=""/> } />
          <Route path='/story/:storyid' element={<SiteBody title="story" description=""/> } />
          <Route path='/poem' element={<SiteBody title="poem" description=""/> } />
          <Route path='/poem/:storyid' element={<SiteBody title="poem" description=""/> } />
          <Route path='/ebooks' element={<SiteBody title="ebooks" description=""/> } />
          <Route path='/ebooks/:storyid' element={<SiteBody title="ebooks" description=""/> } />
          <Route path='*' element={<div><h1 className="container d-flex justify-content-center text-center" > Page Not Exist </h1></div> } />
          <Route path='/about' element={<AboutUs/> } />
          <Route path='/privacypolicy' element={<PrivacyPolicy/> } />
          <Route path='/termsandconditions' element={<TermsAndConditions/> } />
        </Routes>
        <BottomFooter/>
      
      </div>  
    </Router>
  );
}

export default App;
