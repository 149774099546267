import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Container ,Navbar , NavDropdown, Nav} from 'react-bootstrap';
import logo2 from'../components/HushStoryLogo2.png';
import logo1 from '../components/HushStoryLogo1.png';
import { Divider   } from 'antd';


// import NavDropdown from 'react-bootstrap/NavDropdown';

export default function NavbarMenu() {
  return (
    <div>
      <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="/"> <img src={logo1} width="50" height ="50" alt="logo" /> <img src={logo2} width="150"  alt="logo" /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
        <Nav.Link href="/">Home</Nav.Link>
        <Divider  type="vertical"></Divider>
        <Nav.Link href="/poem">Poem</Nav.Link>
        <Divider  type="vertical"></Divider>
        <Nav.Link href="/ebooks">E-Books</Nav.Link>
        <Divider  type="vertical"></Divider>
          {/* <Space wrap>
            <a style={{textDecoration: 'none'}} href="/" > <h6 style={{color: 'black'}}> Home</h6> </a> 
            <Divider  type="vertical" orientationMargin={5}></Divider>
            <a style={{textDecoration: 'none'}} href="/story/latest"> <h6 style={{color: 'black'}}> Story</h6> </a> 
          </Space> */}
          <NavDropdown title="Tools" id="basic-nav-dropdown" style={{color: 'black'}}>
              <NavDropdown.Item href="https://tools.hushstory.com/emi-calculator">Emi Calculator</NavDropdown.Item>
              <NavDropdown.Item href="https://tools.hushstory.com/search-pincode">Search Pincode</NavDropdown.Item>
              {/* <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item> */}
            </NavDropdown>
            {/* <Nav.Link href="#link">Link</Nav.Link> */}
            {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown> */}
         
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </div>
  );
}





