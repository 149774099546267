import React from 'react'

export default function AboutUs() {
  return (
<div>

    
    <div className='container' style={{marginTop: '40px' , minHeight: '750px' , padding: "70px"}}>               
    <div className='container d-flex justify-content-center text-center'><h1>About Us</h1></div>
    <br/>
    <br/>
    <p>We believe that stories are the best way to communicate ideas, and we want to make sure everyone has access to them.</p>

    <p>Our mission is to make a story on everything and make them readable to all people. We also have a tagline: <span>"Make Stories Accessible."</span></p>
    
    <p>Whether it's a meme, a blog post, or a conversation you overheard in a coffee shop—if someone is having something interesting to say and they want to tell someone else about it, we're all ears.</p>
    
    <p>We started HushStory because we believe in sharing stories and we were frustrated with the way most people consume news and entertainment. We wanted a platform that would allow users to watch their favorite stories, over and over again, without having to worry about bandwidth limits or other technical issues.</p>
    
    <p>But sometimes the people who have something interesting to say don't know how to share it. Or maybe they think it's not worth sharing and will never be read by anyone other than themselves. We want to change that. We want to make sure everyone has access to the stories they need to be heard so that everyone can get up-to-date with what's going on in the world today.</p>
    
    <p>We believe that everyone should be able to watch, rewatch, and share the stories they love—even if they're not popular. That's why we created HushStory. Our mission is to make sure every story gets the reach it deserves.</p>
    
    <p>So far, we've brought you stories about people who have fallen in love with their pets, people who are trying to save a dying tree from their childhood homes, and people who have been through difficult times with their families. We're looking forward to sharing more of those kinds of stories in the future!</p>
    
    </div>
</div>
  )
}
