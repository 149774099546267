import React from 'react';
import { Card } from 'react-bootstrap';


export default function RelatedStory(props) {
  return (
    
    <Card>
        <Card.Img variant="top" src={props.images} alt={`Related-Story-${props.title}`}/>
        <Card.Body>
          <Card.Title>{props.title}</Card.Title>
        </Card.Body>
        <Card.Footer>
          <small className="text-muted"> Published on {props.date.slice(0,10)}</small>
        </Card.Footer>
    </Card>

    
  )
}
